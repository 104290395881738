import 'prop-types';
import { Map } from 'immutable';
import Sentry from '@dbh/sentry';
import { select, call, put, fork } from 'redux-saga/effects';
import { API_IDS } from '@dbh/api-constants';
import { makeSelectApiUrl } from '@dbh/configuration-redux';
import '@dbh/decorate-webcontrols-list';
import { request } from '@dbh/request-queue-redux';
import { makeSelectCountryAndLocale, makeSelectLocationPathname } from '@dbh/routing-redux';
import { makeSelectSagaRequestOptions } from '@dbh/saga-request-options';
import { selectUserAreaData, makeSelectUserLoaded } from '@dbh/user-area-redux';
import { webcontrolsLoaded, webcontrolsLoadingError } from '@dbh/webcontrols-events-and-constants-redux';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { PAGE_TYPES } from '@dbh/admindev-constants';
import { LOAD_WEBPAGES_SUCCESS, LOAD_WEBPAGES_ROUTES_SUCCESS } from '@dbh/webpages-events-and-constants-redux';

/**
 * The pages that the `admindev` returns as `NotFoundPage` from the `webpages?url=`
 * `API`, are all those that do not exist in the `admindev` for the specific
 * country and locale with wich the `webpages` `API` is called. When calling
 * the `webcontrols?url` `API` with a `NotFoundPage` `URL`, nothing is returned.
 * Therefore, we have to call the `webcontrols?url` `API` with the "main"
 * `NotFoundPage` `URL` for the current country and locale
 * (`urlNotFoundPageCurrentCountryCulture`). That `URL` is the only one that has
 * the `webcontrols` associated. For the `/IT/en-GB` site, for example, the "main"
 * `NotFoundPage` is `/IT/en-GB/39/404`.
 * @see {@link https://apidev.daybreakhotels.com/v1.0/IT/en-GB/webpages?url=/IT/en-GB/39/404}
 * @see {@link https://apidev.daybreakhotels.com/v1.0/IT/en-GB/webcontrols?url=/IT/en-GB/39/404}
 * @param {string} urlNotFoundPageCurrentCountryCulture .
 */const handleLoadNotFoundPageWebcontrols=withConformsTo("handleLoadNotFoundPageWebcontrols",[],function*(a){const b=yield select(makeSelectCountryAndLocale()),c=yield select(makeSelectLocationPathname()),d={queryParameters:Map({url:a,_makeUrlRelative:!0})},e=yield select(makeSelectApiUrl(API_IDS.webcontrols,d)),f=yield select(makeSelectSagaRequestOptions(API_IDS.webcontrols));// Make the `API` request.
try{const a=yield call(request,e,f),{apiResponse:d,requestAttributes:g}=a,h=yield select(selectUserAreaData),i=yield select(makeSelectUserLoaded());let j=d["hydra:member"];yield put(webcontrolsLoaded({webcontrols:j,requestAttributes:g,locale:b.get("locale"),country:b.get("country"),pathname:c,userAreaData:h,isUserLoaded:i}));}catch(a){Sentry.captureException(a),yield put(webcontrolsLoadingError(a));}});var handleLoadNotFoundPageWebcontrols$1 = handleLoadNotFoundPageWebcontrols;

/**
 * @typedef {import('@dbh/redux-saga-extra').SagaEventResult} SagaEventResult
 *//**
 * Loading webcontrols for non found web page.
 * @param {Object} event `redux` event.
 * @yields {SagaEventResult?} .
 */function*handleLoadNotFoundPageWebcontrolsIfRequired(a){const{payload:b,type:c}=a;// Here, even if we take for granted that this watcher is forked only for
// `SSR` and only for `LOAD_WEBPAGES_SUCCESS` and
// `LOAD_WEBPAGES_ROUTES_SUCCESS`, actually it is taking ANY event. So,
// we manually check only for the events that interest us in this case.
if(c!==LOAD_WEBPAGES_SUCCESS&&c!==LOAD_WEBPAGES_ROUTES_SUCCESS)return;const{templateCode:d}=b,e=b.webpages.get("url");if(d){const a=d===PAGE_TYPES.NotFoundPage;a&&(yield fork(handleLoadNotFoundPageWebcontrols$1,e));}}

export { handleLoadNotFoundPageWebcontrols$1 as handleLoadNotFoundPageWebcontrols, handleLoadNotFoundPageWebcontrolsIfRequired };
