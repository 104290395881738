import { useCallback } from 'react';
import 'prop-types';
import createUseBoundEventCreator from '@dbh/create-use-bound-event-creator';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { put } from 'redux-saga/effects';

const NAVIGATED_TO_NEW_TAB_OR_WINDOW="navigate-to-new-tab-or-window/NAVIGATED_TO_NEW_TAB_OR_WINDOW";

/**
 * Dispatched when the user navigates to a new page, which is opened in a new tab.
 * @param {string} url .
 * @return {Object} .
 */const navigatedToNewWindow=withConformsTo("navigatedToNewWindow",[],a=>({type:NAVIGATED_TO_NEW_TAB_OR_WINDOW,payload:{url:a}}));const useNavigatedToNewWindow=createUseBoundEventCreator(navigatedToNewWindow);var navigatedToNewWindow$1 = navigatedToNewWindow;

const useNavigateToNewTabOrWindow=()=>{const a=useNavigatedToNewWindow(),b=useCallback(b=>{// eslint-disable-next-line no-restricted-syntax
window.open(b,"_blank"),a(b);},[a]);return b};var useNavigateToNewTabOrWindow$1 = useNavigateToNewTabOrWindow;

const navigateToNewTabOrWindow=withConformsTo("navigateToNewTabOrWindow",[],a=>// eslint-disable-next-line no-restricted-syntax
window.open(a,"_blank"));var navigateToNewTabOrWindow$1 = navigateToNewTabOrWindow;

/**
 * Handles the navigation to a new tab or window.
 * @param {string} url .
 * @return {Object} .
 * @yields {Object} .
 */const handleNavigateToNewWindow=withConformsTo("handleNavigateToNewWindow",[],function*(a){const b=navigateToNewTabOrWindow$1(a);return yield put(navigatedToNewWindow$1(a)),b});var handleNavigateToNewWindow$1 = handleNavigateToNewWindow;

export { NAVIGATED_TO_NEW_TAB_OR_WINDOW, handleNavigateToNewWindow$1 as handleNavigateToNewWindow, navigatedToNewWindow$1 as navigatedToNewWindow, useNavigateToNewTabOrWindow$1 as useNavigateToNewTabOrWindow, useNavigatedToNewWindow };
